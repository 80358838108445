import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/attendance": [3],
		"/attendance/clockin": [~4],
		"/attendance/dashboard": [~5],
		"/attendance/dashboard/calendar": [~6],
		"/attendance/leave": [~7],
		"/attendance/leave/add": [~8],
		"/attendance/leave/delete": [~9],
		"/attendance/leave/delete/[leaveID]": [~10],
		"/attendance/leave/edit": [~11],
		"/attendance/leave/edit/[leaveID]": [~12],
		"/attendance/student": [~13],
		"/attendance/student/[emailID]": [~14],
		"/reset": [~15],
		"/shifts": [16],
		"/shifts/list": [~17],
		"/shifts/signup": [~18],
		"/staff": [19],
		"/staff/add": [~20],
		"/staff/calendar": [~21],
		"/staff/calendar/add": [~22],
		"/staff/calendar/delete": [~23],
		"/staff/calendar/delete/[leaveID]": [~24],
		"/staff/calendar/edit": [~25],
		"/staff/calendar/edit/[leaveID]": [~26],
		"/staff/delete": [~27],
		"/staff/delete/[emailID]": [~28],
		"/staff/edit": [~29],
		"/staff/edit/[emailID]": [~30],
		"/staff/list": [~31],
		"/staff/list/[emailID]": [~32],
		"/students": [33],
		"/students/add": [~34],
		"/students/delete": [~35],
		"/students/delete/[emailID]": [~36],
		"/students/edit": [~37],
		"/students/edit/[emailID]": [~38],
		"/students/list": [~39],
		"/students/list/[emailID]": [~40]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';