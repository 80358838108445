import { init } from "@jill64/sentry-sveltekit-cloudflare/client";

const onError = init("https://abfe32fc60f0ad0b2afe3bb0f283af5f@o4506932734787584.ingest.us.sentry.io/4506932741799936", {
    sentryOptions: {
        environment: import.meta.env.MODE,
    },
    // enableInDevMode: true,
});

export const handleError = onError((e, sentryEventId) => ({
    message: e.message,
    sentryEventId,
}));
